.body {
  overflow: hidden;
}

#root {
  height: -webkit-fill-available;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mapboxgl-popup {
    padding-bottom: 150px;
  }
}

.mapboxgl-popup {
  padding-left: 0px;
  padding-bottom: 20px;
}

.mapboxgl-popup-content {
  position: relative;
  border-radius: 6px; 
  padding: 0 20px 0;
}

.mapboxgl-popup-content::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 6px; 
  padding: 1px; 
  background: radial-gradient( 116.48% 510.54% at 1.96% -1.75%, #EF2FDC 0%, #E2FE70 100%) border-box; /*3*/
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}


.mapboxgl-popup-content > div {
  padding: 0.875rem;
}

.mapboxgl-popup-close-button {
  top: 0px;
  right: 0px;
  font-size: 16px;
}

.mapboxgl-popup-close-button:focus {
  outline: 0cm;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
  border-bottom-left-radius: 30px !important;
  margin-bottom: 15px;
}

.mapboxgl-ctrl-geocoder {
  display: none;
}