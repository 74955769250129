@font-face {
  font-display: swap;
  font-family: "icons";
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/icons/icons.ttf') format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Figtree/Figtree-VariableFont_wght.ttf') format('truetype');
}


@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Figtree/Figtree-VariableFont_wght.ttf') format('truetype');
}


@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Figtree/Figtree-Italic-VariableFont_wght.ttf') format('truetype');
}

/* Bold Font */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Figtree/Figtree-VariableFont_wght.ttf') format('truetype');
}